<template>
  <div class="csn-dashboard">
    <section class="user-dashboard">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <Route :to="{ name: routeName.depositHistory }">
              <div class="row-fluid box csn-dashboard-data">
                <div class="col-md-6 col">
                  <p class="title">{{ CASINO_INFO.accountBalance }}</p>
                  <p class="amount">
                    {{ t('balance') }}
                  </p>
                </div>
                <div class="col-md-6 col text-right">
                  <Route
                    :to="{ name: routeName.deposit }"
                    class="btn casino-btn casino-btn-default casino-btn-pink csn-dashboard-btn"
                  >
                    {{ t('deposit') }}
                  </Route>
                </div>
              </div>
            </Route>
          </div>
          <div class="col-md-4">
            <div class="row-fluid box csn-dashboard-data">
              <div class="col-md-6">
                <p class="title csn-dashboard-number-sign-data">
                  <span>{{ platinCoins }}</span>
                  <img
                    src="@/assets/images/icons/general/Rewards-Carnival.svg"
                    alt="rewards"
                  />
                </p>
                <p class="amount">{{ t('platin_points') }}</p>
              </div>
              <div class="col-md-6 col text-right">
                <Route
                  :to="{ name: routeName.rewardCarnival }"
                  class="btn casino-btn casino-btn-default casino-btn-deposit csn-dashboard-btn"
                >
                  {{ t('redeem') }}
                </Route>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="row-fluid box csn-dashboard-data">
              <div class="col-md-6">
                <p class="title csn-dashboard-number-sign-data">
                  <span>{{ userNotifications }}</span>
                  <img
                    src="@/assets/images/icons/general/Bell.svg"
                    alt="bell"
                  />
                </p>
                <p class="amount">{{ t('notifications') }}</p>
              </div>
              <div class="col-md-6 col text-right">
                <a
                  href="#"
                  @click="handleNotificationClick"
                  class="btn casino-btn casino-btn-default casino-btn-deposit csn-dashboard-btn"
                >
                  {{ t('view') }}
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 container-with-img-header">
            <img
              src="@/assets/images/dashboard-premium-card.png"
              class="img-header"
            />
            <div class="row-fluid box">
              <div class="col-md-6">
                <p class="title">{{ t('platin_club') }}</p>
                <p class="amount">{{ t('loyalty_program') }}</p>
              </div>
              <div class="col-md-6 col text-right">
                <Route
                  :to="{ name: routeName.platinClub }"
                  class="btn casino-btn casino-btn-default casino-btn-deposit csn-dashboard-btn"
                >
                  {{ t('more') }}
                </Route>
              </div>
            </div>
          </div>
          <div class="col-md-4 container-with-img-header">
            <img
              src="@/assets/images/dashboard-rewards-carnival.png"
              class="img-header"
            />
            <div class="row-fluid box">
              <div class="col-md-6">
                <p class="title">{{ t('rewards') }}</p>
                <p class="amount">{{ t('carnival') }}</p>
              </div>
              <div class="col-md-6 col text-right">
                <Route
                  :to="{ name: routeName.rewardCarnival }"
                  class="btn casino-btn casino-btn-default casino-btn-deposit csn-dashboard-btn"
                >
                  {{ t('more') }}
                </Route>
              </div>
            </div>
          </div>
          <div class="col-md-4 container-with-img-header">
            <img
              src="@/assets/images/dashboard-freespin.png"
              class="img-header"
            />
            <div class="row-fluid box">
              <div class="col-md-6">
                <p class="title">{{ t('free_spins') }}</p>
                <p class="amount">{{ t('special_offer') }}</p>
              </div>
              <div class="col-md-6 col text-right">
                <Route
                  :to="{ name: routeName.promotionInfo }"
                  class="btn casino-btn casino-btn-default casino-btn-deposit csn-dashboard-btn"
                >
                  {{ t('more') }}
                </Route>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="row-fluid box csn-dashboard-bonus-container">
              <div class="col-md-12">
                <ul class="bonus-codes-section">
                  <li>
                    <label>{{ t('last_used_bonus_codes') }}</label>
                  </li>
                  <!-- {% for bonusCode in lastUsedBonusCodes %} -->
                  <li>
                    <button>{{ bonusCode }}</button>
                  </li>
                  <!-- {% endfor %} -->
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div :ref="ref" class="csn-dashboard-game-list-container">
      <HorizontalList
        :title="t('recently_played')"
        :itemsPerScreen="itemsPerScreen"
        :getList="getRecentGameList"
      />
      <HorizontalList
        :title="t('favourites')"
        :itemsPerScreen="itemsPerScreen"
        :getList="getFavouriteGameList"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import {
  DASHBOARD,
  RouteName,
  Module,
  CASINO_INFO,
  USER_NOTIFICATION_LIST,
  IS_USER_NOTIFICATION_LIST_PENDING,
  TOGGLE_USER_NOTIFICATION_SIDE_MENU,
  IS_MOBILE_SCREEN,
  TOGGLE_DESKTOP_NOTIFICATION_MENU,
} from '@/constants'
import { dest } from '@/helpers'
import { cardsPerScreenCalculationMixin } from '@/mixins'
import { GameApi } from '@/api'
import * as CasinoInfo from '@/models/getters/casinoInfo'

export default {
  name: DASHBOARD,
  mixins: [cardsPerScreenCalculationMixin],
  components: {
    HorizontalList: () => import('@/components/HorizontalList'),
    Route: () => import('@/components/Route'),
  },
  computed: {
    ...mapState(Module.CASINO_INFO, { CASINO_INFO }),
    ...mapState(Module.USER_NOTIFICATION, [
      USER_NOTIFICATION_LIST,
      IS_USER_NOTIFICATION_LIST_PENDING,
    ]),
    ...mapGetters({ isMobileScreen: dest([Module.DEVICE, IS_MOBILE_SCREEN]) }),
    routeName: () => ({
      deposit: RouteName.DEPOSIT,
      depositHistory: RouteName.USER_DEPOSIT_HISTORY,
      rewardCarnival: RouteName.REWARD_CARNIVAL,
      platinClub: RouteName.PLATINCLUB,
      notifications: RouteName.USER_NOTIFICATIONS,
      promotionInfo: RouteName.OFFERS_VIEW,
    }),
    t() {
      return this.$createComponentTranslator(DASHBOARD)
    },
    userNotifications() {
      return Number(this.USER_NOTIFICATION_LIST?.length)
    },
    bonusCode() {
      return 'WIP'
    },
    platinCoins() {
      return CasinoInfo.getPlatinCoins(this.CASINO_INFO)
    },
  },
  methods: {
    ...mapMutations(Module.MAIN, [
      TOGGLE_USER_NOTIFICATION_SIDE_MENU,
      TOGGLE_DESKTOP_NOTIFICATION_MENU,
    ]),
    handleNotificationClick() {
      this.isMobileScreen
        ? this.TOGGLE_USER_NOTIFICATION_SIDE_MENU(true)
        : this.TOGGLE_DESKTOP_NOTIFICATION_MENU(true)
    },
    getRecentGameList: GameApi.getRecentGameList,
    getFavouriteGameList: GameApi.getFavouriteGameList,
  },
}
</script>
